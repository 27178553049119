import styled from "styled-components";
import { SectionWrapper } from "shared/Styles/Wrappers";
import { Button } from "components/Buttons";

export const Container = styled(SectionWrapper)`
  margin: 72px auto;
`;

export const Item = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 20px 0;
`;

export const ItemWrapper = styled.div`
  position: relative;
  padding: 32px 64px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 24px 24px;
  }
`;

export const Title = styled.h2`
  color: ${({ color, theme }) => (color ? color : theme.colors.black)};
  font-weight: 700;
  font-size: 2.2rem;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 2rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    text-align: center;
  }
`;

export const PositionInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Category = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.gray_text};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    text-align: center;
  }
`;

export const ButtonWrapper = styled.div``;

export const StyledButton = styled(Button)`
  min-width: 0 !important;
  min-height: 0 !important;
  width: 160px;
  padding: 12px;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    margin-top: 24px;
  }
`;
