import React from "react";
import SEO from "components/seo";
import useHerosTypesData from "../shared/Hooks/PageHeaders/useHerosTypesData";
import {
  CenteredHeader,
  Container as FormContainer,
  Form,
} from "components/Form";
import { JobsList } from "../sections/Career";
import { GlobalWrapperBg } from "sections/Career/styles";
import { TextWrapper } from "shared/Styles/Wrappers";
import { TitleXL } from "components/Typography";
import { BigHero } from "components/BigHero";
import { CAREER } from "../shared/Types/heros";
import { CAREER_IFRAME_NAME } from "../components/Form/form";
import { CenteredTextBox } from "components/TextBoxes";

export const Career = () => {
  const data = useHerosTypesData(CAREER);

  return (
    <GlobalWrapperBg>
      <SEO title={data.title} description={data.seo} />
      <BigHero data={data} />
      <CenteredTextBox
        titleTag="h2"
        title="Stań się częścią naszego zespołu"
        text={
          "Jesteśmy kreatywnym zespołem, który stawia na ciągły rozwój. Szukamy osób - które tak jak my - każdego dnia są gotowe na nowe wyzwania i nie boją się ich podejmować. Zobacz, na jakie stanowiska aktualnie prowadzimy rekrutację. Jeśli nie widzisz oferty, która Cię interesuje, a chcesz dołączyć do naszego zespołu - odezwij się! Na pewno nie zostawimy Cię bez odpowiedzi."
        }
      />
      <JobsList />
      <TextWrapper>
        <FormContainer>
          <CenteredHeader>
            <TitleXL underline>Dołącz do nas!</TitleXL>
          </CenteredHeader>
          <Form
            src={process.env.GATSBY_CAREER_FORM_URL}
            name={CAREER_IFRAME_NAME}
          />
        </FormContainer>
      </TextWrapper>
    </GlobalWrapperBg>
  );
};

export default Career;
