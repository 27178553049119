import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  ButtonWrapper,
  StyledButton,
  Category,
  Container,
  Item,
  ItemWrapper,
  Line,
  PositionInfo,
  Title,
} from "./styles";

/*const data = {
  "jobs": [
    {
      "name": "Marketing",
      "image": "office",
      "offers": [
        {
          "jobName": "Specjalista do spraw marketingu",
          "jobPlace": "Rzeszów, na miejscu",
          "salary": "Indywidualnie",
          "department": "Marketing",
          "formKey": "marketing",
          "tasks": [
            "Pomoc w opracowaniu strategii marketingowych dla naszych klientów.",
            "Weryfikacja i rozwinięcie aktualnych strategii działań promocyjnych i marketingowych.",
            "Bezpośrednia współpraca z działem sprzedaży i marketingu oraz projekt managerami - opracowywanie kampanii, bieżące wsparcie działań handlowych i marketingowych.",
            "Organizacja marketingu, inspirowanie, rozwijanie i prowadzenie ludzi do realizacji celów."
          ],
          "weoffer": [
            "Współpracę z doświadczonym, ambitnym i otwartym zespołem specjalistów przy dużych i angażujących projektach z możliwością wdrażania i realizowania własnych pomysłów.",
            "Możliwość wywierania wpływu na kształt i długookresową strategię rozwoju firmy oraz jej klientów.",
            "Partnerskie podejście do współpracowników w zakresie rozwoju i budowy marek.",
            "Świadome podejście do marketingu i realizację długookresowych działań promocyjnych.",
            "Wysokiej klasy sprzęt uprzyjemniający Ci codzienną pracę.",
            "Elastyczne godziny pracy."
          ],
          "requirements": [
            "Posiadasz wykształcenie kierunkowe",
            "Masz doświadczenie w kierowaniu dużymi projektami marketingowymi i w marketingowym wsparciu budowy kanałów marek z różnych branż.",
            "Potrafisz tworzyć i wdrażać zaawansowane strategie marketingowe oraz przeprowadzać audyty marketingowe.",
            "Chętnie wychodzisz z inicjatywą i dzielisz się swoimi pomysłami, które nastawione są na cel i skuteczność.",
            "Znasz bieżące trendy i narzędzia reklamowe oraz analityczne.",
            "Wiesz jak wskazać odpowiedni kierunek rozwoju i zorganizować pracę zespołu, a jednocześnie jesteś osobą otwartą i nastawioną na współpracę.",
            "Lubisz pracę z ludźmi oraz masz wesołe i przyjazne nastawienie! :)"
          ],
          "goodToHave": [],
          "status": "Aktualne",
          "url": "/specjalista-do-spraw-marketingu"
        }
      ]
    },
    {
      "name": "Programowanie",
      "image": "office",
      "offers": [
        {
          "jobName": "Junior Backend Developer",
          "jobPlace": "Rzeszów, na miejscu",
          "salary": "Indywidualnie",
          "department": "Programowanie",
          "formKey": "development",
          "tasks": [
            "Współpraca z działem frontendowym oraz graficznym.",
            "Uczestniczenie w wewnętrznych spotkaniach projektowych.",
            "Wdrażanie i realizacja projektów na podstawie projektów graficznych z wykorzystaniem najnowszych technologii, narzędzi oraz dobrych praktyk."
          ],
          "weoffer": [
            "Podniesienie swoich kwalifikacji i szlifowanie warsztatu.",
            "Odpowiedzialną i ciekawą pracę w agencji interaktywnej o dużych możliwościach.",
            "Wysokiej klasy sprzęt uprzyjemniający Ci codzienność.",
            "Możliwość rozwoju w dynamicznym zespole.",
            "Wysokiej klasy sprzęt uprzyjemniający Ci codzienną pracę."
          ],
          "requirements": [
            "Znasz język PHP",
            "Znasz HTML oraz CSS",
            "Posiadasz podstawową znajomość języka JavaScript",
            "Posiadasz podstawową znajomość relacyjnych baz danych (np. MySQL)"
          ],
          "goodToHave": [
            "Znajomość framework'a Laravel",
            "Znajomość technologii LiveWire",
            "Znajomość Alpine.js"
          ],
          "status": "Aktualne",
          "url": "/junior-backend-developer"
        }
      ]
    }
  ]
}*/

const data = {
  jobs: []
}


export const JobsList = () => {
  /*const data = useStaticQuery(graphql`
    {
      allJobOffersJson {
        nodes {
          jobs {
            name
            image
            offers {
              jobName
              jobPlace
              status
              url
            }
          }
        }
      }
    }
  `);*/

  return (
    <Container>
      {data.jobs.map((item, i) =>
        item.offers.map((offer, x) => (
          <Item key={`job_offer_${i}_${x}`}>
            <ItemWrapper>
              <Line>
                <PositionInfo>
                  <Category as="h2">{item.name}</Category>
                  <Title>{offer.jobName}</Title>
                </PositionInfo>
                <ButtonWrapper>
                  <StyledButton to={`/kariera${offer.url}`}>
                    zobacz więcej
                  </StyledButton>
                </ButtonWrapper>
              </Line>
            </ItemWrapper>
          </Item>
        ))
      )}
    </Container>
  );
};
